<template>
  <b-sidebar
    id="SidebarFilterTransaction"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    width="85vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <p class="mt-3 mb-2 p-black">Search</p>
      <b-input-group>
        <b-form-input
          type="search"
          class="search-bar"
          placeholder="Search Invoice Number"
          v-model="filter.search"
        >
        </b-form-input>
      </b-input-group>
      <div class="mt-3">
        <label class="mb-2">Start Date - End Date</label>
        <div class="date-input">
          <span
            v-if="!filter.transaction_date_from"
            @click="$refs.dateStart.isOpen = true"
            class="w-40 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateStart.isOpen = true"
            class="w-50 pointer"
            >{{ filter.transaction_date_from | moment($formatDateNew) }}</span
          >
          <span class="px-2">-</span>
          <span
            v-if="!filter.transaction_date_to"
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >{{ filter.transaction_date_to | moment($formatDateNew) }}</span
          >
          <div :class="'icon-primary text-right'">
            <font-awesome-icon
              icon="calendar-alt"
              @click="$refs.dateStart.isOpen = true"
              :class="'cursor-pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <datetime
          ref="dateStart"
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          v-model="filter.transaction_date_from"
          :max-datetime="filter.transaction_date_to || dateFormat()"
        >
        </datetime>
        <datetime
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          :max-datetime="dateFormat()"
          :min-datetime="filter.transaction_date_from"
          ref="dateEnd"
          v-model="filter.transaction_date_to"
        >
        </datetime>
      </div>
      <div class="mt-3">
        <MultiSelect
          :used="false"
          textFloat="Branch"
          :options="branchList"
          textField="name"
          valueField="id"
          placeholder="Select Branch"
          v-model="filter.branch_filter"
          :value="filter.branch_filter"
        />
      </div>
      <div class="my-3">
        <MultiSelect
          :used="false"
          textFloat="Source Name"
          :options="saleChanel"
          textField="name"
          valueField="id"
          placeholder="Select Source Name"
          v-model="filter.source_id"
          :value="filter.source_id"
        />
      </div>
      <div class="my-3">
        <MultiSelect
          :used="false"
          textFloat="Transaction Status"
          :options="statusFilter"
          textField="name"
          valueField="id"
          placeholder="Select Status"
          v-model="filter.status_id"
          :value="filter.status_id"
        />
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "PendingFilter",
  components: {
    InputSelect,
    Multiselect,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      selected: [],

      actionSelect: [],
      birthMonth: [],
      statusFilter: [
        { id: 1, name: "Complete" },
        { id: 2, name: "Void/Returned" },
        { id: 4, name: "Deleted" },
      ],
      saleChanel: [
        { id: 2, name: "MIM" },
        { id: 1, name: "Booth" },
      ],
      branchList: [],
      filterBranch: [],
    };
  },
  watch: {},
  created() {},
  methods: {
    async getBranchByFilter() {
      this.$bus.$emit("showLoading");
      // await this.$store.dispatch("getBranchFilterTransaction");
      if (this.branchList.length > 0) return this.$bus.$emit("hideLoading");
      const data = await this.axios(`/branch/listAll`);

      if (data.data.result == 1) {
        this.branchList = data.data.detail;
      }
      // this.isLoading = false;
      this.$bus.$emit("hideLoading");
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },

    async show() {
      await this.getBranchByFilter();
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },

    clearFilter() {
      this.filter = {
        search: "",
        transaction_date_from: "",
        transaction_date_to: "",
        branch_filter: [],
        source_id: [],
        status_id: [],
        page: 1,
        take: 10,
      };

      this.$emit("submitFilter", this.filter);
      this.hide();
    },
    submitAction() {
      this.filter.page = 1;

      this.$emit("submitFilter", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.w-40 {
  width: 40%;
}

.form-group {
  margin-bottom: 0.5rem;
}
</style>
