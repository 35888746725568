<template>
  <div>
    <HeaderPanel
      title="Transaction Pending"
      :hasDropdown="false"
      :filter="filter"
      placeholder="Search Name, Telephone, Email"
      :hideCreate="false"
      @sidebar="openFilter"
      @search="getList"
    >
      <template #extra-button>
        <div>
          <button class="ml-2 btn-mobile btn button">Retry All</button>
        </div>
      </template>
    </HeaderPanel>
    <div class="text-bold">ALL : {{ data.total | numeral("0,0") }}</div>

    <!-- <ul> -->
    <div class="d-flex column-gap-md">
      <li class="text-warning">
        PENDING : {{ data.total_pending | numeral("0,0") }}
      </li>
      <li class="text-success">SUCCESS : {{ data.total_success }}</li>
    </div>
    <!-- </ul> -->

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(first_name_th)="data">
              <b-row>
                <b-col>
                  <router-link :to="`/report/customer/${data.item.user_guid}`">
                    <u>
                      {{ data.item.first_name_th }}
                      {{ data.item.last_name_th }}
                    </u>
                  </router-link>
                </b-col>
              </b-row>
            </template>

            <template v-slot:cell(created_time)="data">
              <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.created_time | moment("HH:mm:ss") }}
              </div>
            </template>

            <template v-slot:cell(updated_time)="data">
              <div>{{ data.item.updated_by }}</div>
              <div class="time-color" v-if="data.item.updated_time">
                {{ data.item.updated_time | moment("DD/MM/YYYY (HH:mm:ss)") }}
              </div>
              <div v-else>-</div>
            </template>

            <template v-slot:cell(invoice_no)="data">
              <div class="text-left title-transaction pointer underline">
                <router-link
                  :to="`/report/transaction-pending/${data.item.transaction_id}`"
                  >{{
                    data.item.invoice_no ? data.item.invoice_no : "-"
                  }}</router-link
                >
              </div>
            </template>
            <template v-slot:cell(grand_total)="data">
              <div class="">
                {{ data.item.grand_total | numeral("0,0.00") }}
              </div>
            </template>
            <template v-slot:cell(member_id)="data">
              <div class="text-left">
                <div
                  class="title-transaction pointer underline"
                  v-if="data.item.member_id"
                  @click="
                    $router.push(`/report/customer/${data.item.user_guid}`)
                  "
                >
                  {{ data.item.member_id }}
                </div>
                <div class="title-transaction" v-else>-</div>
                <div>
                  {{ data.item.customer_name ? data.item.customer_name : "-" }}
                </div>
              </div>
            </template>
            <template v-slot:cell(status_name)="data">
              <div>
                <div v-if="data.item.deleted === 1" class="text-danger">
                  Deleted
                </div>
                <div v-else-if="data.item.status_id === 1">
                  <span class="text-success">Complete </span>
                  <span class="status-void" v-if="data.item.is_void === 1"
                    >(Void/Returned)</span
                  >
                </div>
                <div
                  v-else-if="
                    data.item.status_id === 2 || data.item.status_id === 3
                  "
                  class="status-void"
                >
                  Void/Returned
                </div>
                <div v-else-if="data.item.status_id === 4" class="text-danger">
                  {{ data.item.status_name }}
                </div>
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <span class="text-success" v-if="data.item.status == 1">
                Success
              </span>
              <span class="text-warning" v-else> Pending </span>
            </template>
            <template v-slot:cell(action)="data">
              <b-dropdown
                size="md"
                variant="link"
                toggle-class="text-decoration-none text-black p-0"
                class="automation"
                no-caret
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v" class="mt-2 pointer" />
                </template>
                <b-dropdown-item-button
                  v-if="data.item.status_id === 1"
                  @click.prevent="
                    $router.push(
                      '/report/transaction-pending/' + data.item.transaction_id
                    )
                  "
                >
                  Detail
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-if="data.item.status === 0"
                  @click.prevent="retry(data.item.transaction_id)"
                >
                  Retry
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-if="data.item.status === 0"
                  @click.prevent="resendMessage(false, data.item.id)"
                >
                  Delete
                </b-dropdown-item-button>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>

    <!-- <router-view></router-view> -->
    <FilterComponent ref="filter" :filter="filter" @submitFilter="getList" />
  </div>
</template>
<script>
import FilterComponent from "./SidebarFilter";

export default {
  name: "TransactionPending",
  components: {
    FilterComponent,
    // HeaderPanel,
  },
  data() {
    return {
      filter: {
        search: "",
        transaction_date_from: "",
        transaction_date_to: "",
        branch_filter: [], // list int
        source_id: [], // list int
        channel_id: [], // list int
        status_id: [], // list int (transaction status)
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          label: "Pos/Mim No.",
          key: "invoice_no",
        },
        {
          label: "Member ID/Customer Name",
          key: "member_id",
        },
        {
          label: "Days/Time Spent",
          key: "created_time",
        },
        {
          label: "Grand Total",
          key: "grand_total",
        },

        {
          label: "Updated Time",
          key: "updated_time",
        },

        {
          label: "Transaction Status",
          key: "status_name",
        },

        {
          label: "Status",
          key: "status",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getAnaly();
  },

  methods: {
    async getList() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/transaction/transactionpending/list`,
        this.filter
      );

      this.rows = response.data.detail.total_count;
      this.items = response.data.detail.transaction_list;
      this.isBusy = false;
    },
    async getAnaly() {
      const response = await this.axios.post(
        `/transaction/transactionpending/analysis`,
        this.filter
      );
      this.data = response.data.detail;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    openFilter() {
      this.$refs.filter.show();
    },
    async retry(id) {
      let payload = {
        id: id,
      };
      this.$bus.$emit("showLoading");
      const resp = await this.axios.post("/transaction/retry", payload);
      if (resp.data.result) this.successAlert();
      else this.errorAlert(resp.data.message);
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
